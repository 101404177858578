<template>
  <v-card class="card-shadow border" color="white">
    <v-row align="end" class="pa-8" style="gap: 30px">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
      <v-btn color="primary" @click.prevent="currentPage = 1;Contacts(search)">
        Search
      </v-btn>
    </v-row>
    <v-data-table :headers="headers" :items="contactData" hide-default-footer :loading="loading"
                  :loading-text="message" :items-per-page="currentRows">
      <template v-slot:item.action="{ item }">
        <v-btn small @click="handleTransaction(item.id)" class="ma-2" outlined color="primary">
          {{ item.action }}
        </v-btn>
      </template>
    </v-data-table>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
                      :total-visible="7" />
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>

export default {
  data() {
    return {
      currentPage: 1,
      noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50],
      currentRows: 10,

      contactData: [],
      data: '',
      message: 'Loading... Please wait',
      loading: true,
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Address', value: 'address' },
        { text: 'Zip code', value: 'zip_code' },
        { text: 'Action', value: 'action' },
      ],

      abortController: new AbortController()
    };
  },
  props: {
    currentServer: Object,
  },
  methods: {
    handleTransaction(id) {
      this.$router.push(`/skyfi-dashboard/transactions/${id}/?for=contact`);
    },
    async Contacts(searchString) {
      try {
        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        const accountId = this.$route.params.id ? '&account_id=' + this.$route.params.id : '';
        const SearchString = searchString ? '&search_string=' + searchString : '';

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/contacts${v_page + v_per_page + accountId + SearchString}`,
          {
            method: 'GET',
            headers: myHeaders,
            'Content-Type': 'application/json',
            redirect: 'follow',
            signal
          }
        );
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        const accountContact = data?.data;
        this.data = accountContact
        const contactArray = accountContact?.map((item) => {
          return {
            id: item.id,
            name: item.attributes.first_name + ' ' + item.attributes.last_name,
            phone: item.attributes.phone,
            email: item.attributes.email,
            address: (item?.attributes?.address_1 ?? '') + (item?.attributes?.address_2 ? ', ' + item?.attributes?.address_2 : '') + (item?.attributes?.city ? ', ' + item?.attributes?.city : '') + (item?.attributes?.state ? ', ' + item?.attributes?.state : '') + (item?.attributes?.country ? ', ' + item?.attributes?.country : ''),
            zip_code: item?.attributes?.zip_code,
            action: 'Transaction',
          }
        })
        this.contactData = contactArray;
        this.noOfPages = Math.ceil(parseInt(data?.total_count) / this.currentRows)
        this.loading = false
        this.message = 'something went wrong'
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Network response was not ok'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
        this.loading = false
        this.message = 'something went wrong'
      }
    },
    pageChange() {
      this.Contacts(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.Contacts(this.search);
    },
  },
  mounted() {
    this.Contacts();
  },
  watch: {
    $route() {
      this.message = 'Loading... Please wait'
      this.loading = true
      this.search = ''
      this.Contacts()
    },
  },
};
</script>
    